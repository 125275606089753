<template>
  <div>
      <Header />
  <div class="body-recommend" style="padding-top:150px">
  
    <!-- Tab Selection -->
    <div class="tab-selection flex" >
      <ul>
        <li :class="active_class == 'recommend' ? 'active' : ''">
          <a :href="`/${i18n.locale}/recommend`">{{$t("home.upcoming-matches")}}</a>
        </li>
        <li :class="active_class == 'match-result' ? 'active' : ''">
          <a :href="`/${i18n.locale}/match-result`">{{$t("home.match-result")}}</a>
        </li>
      </ul>
    </div>
    <section class="main-container pb-5" >
      <div class="container">
        <div class="content">
          <div class="containe mt-4" v-if="bannerTop">
            <!-- Banner -->
            <div
              v-for="(banner, key) in bannerTop"
              :key="key"
              class="mt-2 mb-2"
            >
              <a :href="banner.target_url" target="_blank"
                ><img style="width: 100%" :src="banner.path"
              /></a>
            </div>
          </div>
          <div class="container table-result">
            <div class="table-upcoming card-bg-blue">
              <!-- Row -->
              <div
                class="row table--4cols"
                v-for="(item, index) in dataMatchResultObj"
                :key="index"
              >
                <!-- First -->
                <div class="column-cell-1">
                  <div class="card-matches-title">{{ item.league_name }}</div>
                  <div class="result-live text-center">
                    <a
                      href=""
                      class="btn btn-block btn-sm btn-danger font-weight-bold"
                    >
                      {{ item.open_date }}</a
                    >
                    <div class="final-score card-bg-grey">
                      <h3 class="font-weight-bold">
                        {{ item.home_team_score || "?" }} :
                        {{ item.away_team_score || "?" }}
                      </h3>
                    </div>
                  </div>
                </div>
                <!-- Second -->
                <div class="column-cell-2">
                  <div class="flex" style="align-items: baseline">
                    <div class="home-team text-center">
                      <img src="../assets/img/soccer.png" alt="" />
                      <span class="team-title">{{ item.home_team }}</span>
                    </div>
                    <div class="center-text font-weight-bold"><h3>vs</h3></div>
                    <div class="away-team text-center">
                      <img src="../assets/img/soccer.png" alt="" />
                      <span class="team-title">{{ item.away_team }}</span>
                    </div>
                  </div>
                </div>
                <!-- Third -->
                <div
                  class="column-cell-3"
                  style="justify-content: left; font-size: 16px; padding: 15px"
                >
                  <div>
                   {{$t("nav.free-recom")}}:
                    <span class="text-capitalise mr-1 hightlight-text">{{
                      item.free_analysis
                    }}</span>
                  </div>
                </div>
                <!-- Fourth -->
                <div class="column-cell-4">
                  <div class="modal-button">
                    <a href="" class="btn btn-sm btn-block handicap"
                      >{{$t("home.goal-hand")}} {{ item.handicap }}</a
                    >
                    <a
                      href=""
                      @click="toggleModalMatch"
                      :data-id="index"
                      class="btn btn-sm btn-block btn-live-modal mt-2"
                      >{{$t("home.unravel")}} </a
                    >
                    <a
                      href=""
                      @click="toggleModalMatchReal"
                      :data-id="index"
                      class="btn btn-sm btn-block btn-live-modal mt-2"
                      >{{$t("home.real-order")}} </a
                    >
                    <span
                      class="text-right"
                      :class="
                        item.real_order_handicap_win_or_lose == 'win'
                          ? 'win-or-lose'
                          : 'text-primary'
                      "
                      style="display: block; font-size: 16px; font-weight: bold"
                    >
                      <span v-if="item.real_order_handicap_win_or_lose =='win'">{{$t('nav.win')}}</span>
                      <span v-if="item.real_order_handicap_win_or_lose =='draw'">{{$t('nav.draw')}}</span>
                      <span v-if="item.real_order_handicap_win_or_lose =='lose'">{{$t('nav.lose')}}</span>
                     
                   
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-12" v-show="hasLastPage">
                <infinite-loading
                  @distance="1"
                  @infinite="infiniteHandler"
                ></infinite-loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal ref="my-modal" size="xl" hide-footer :title="$t('home.unravel')">
      <div class="col-lg-12 pb-2 alert fs-12">
        {{ currentUnravel }}
      </div>
      <button
        class="btn btn-info mt-2 mr-2"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{$t("nav.close")}} 
      </button>
    </b-modal>

    <b-modal ref="my-modal-real-order" size="xl" hide-footer :title="$t('home.real_order')">
      <div class="col-lg-12 pb-2 alert fs-12" v-if="currentRealOrder">
        {{ currentRealOrder }}
      </div>

      <table
        class="table"
        v-if="
          currentRealOrderDetail &&
          currentRealOrderDetail.real_order_home_team_score
        "
      >
        <tr>
          <td>{{ currentRealOrderDetail.real_order_handicap }}</td>
          <td>{{ currentRealOrderDetail.real_order_league }}</td>
          <td>
            {{ currentRealOrderDetail.home_team }} vs
            {{ currentRealOrderDetail.away_team }} (0:0) <br />{{
              currentRealOrderDetail.real_order_handicap
            }}
            <br />
            {{ currentRealOrderDetail.home_team }} -
            {{ currentRealOrderDetail.real_order_home_team_score }} @
            {{ currentRealOrderDetail.real_order_home_team_handicap }} ({{$t("nav.finale")}} :
            {{ currentRealOrderDetail.real_order_home_team_score }}-
            {{ currentRealOrderDetail.real_order_away_team_score }})
          </td>
          <td>{{ currentRealOrderDetail.real_order_handicap_one }}</td>
          <td>{{ currentRealOrderDetail.real_order_handicap_two }}</td>
          <td>{{ currentRealOrderDetail.real_order_handicap_three }}</td>
          <td>
              <span v-if="currentRealOrderDetail.real_order_handicap_win_or_lose =='win'">{{$t('nav.win')}}</span>
              <span v-if="currentRealOrderDetail.real_order_handicap_win_or_lose =='draw'">{{$t('nav.draw')}}</span>
              <span v-if="currentRealOrderDetail.real_order_handicap_win_or_lose =='lose'">{{$t('nav.lose')}}</span>
           
          </td>
        </tr>
      </table>

      <button
        class="btn btn-info mt-2 mr-2"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{$t("nav.close")}} 
      </button>
    </b-modal>
    <Footer />
  </div>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import InfiniteLoading from "vue-infinite-loading";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import i18n from "../i18n";

export default {
  name: "MatchResult",
  components: {
    "b-modal": BModal,
    "infinite-loading": InfiniteLoading,
    Header,
    Footer,
  },
  data() {
    return {
      i18n,
      active_class: "",
      real_order_locale:this.$t('home.real-order'),
      unravel_locale:this.$t('home.unravel'),
      page: 1,
      hasLastPage: true,
      limit: 2, //at most for match display
      matchDetail: [],
      currentHost: "https://72963.com",

      bannerHost: "https://72963.com",
      bannerSettings: [
        {
          title: "pc top",
          category: 1,
          status: 1,
          target_url: "https://978bet36.com/",
          cat_title: "电脑-顶部",
          path:
            "https://72963.com/uploads/images/20201214/5696d802b494e7f762dac27e22f08513.gif",
          width: 970,
          height: 150,
        },
        {
          title: "72963-pc",
          category: 1,
          status: 1,
          target_url: "https://77577.com",
          cat_title: "电脑-顶部",
          path:
            "https://72963.com/uploads/images/20201214/banner-77577-desktop.png",
          width: 970,
          height: 150,
        },
      ],
      matchResult: null,
      currentUnravel: "",
      currentRealOrder: "",
      currentRealOrderDetail: "",
    };
  },
  props: {
    msg: String,
  },
  computed: {
    bannerTop() {
      if (this.bannerSettings != null) {
        return this.bannerSettings.filter(function (item) {
          return item.category == 1;
          //return item;
        });
      } //

      return "";
    },
    dataMatchResultObj() {
      if (this.matchDetail) {
        //  const today = new Date().getTime();
        //const filterByExpiration = this.matchDetail.filter((items) => {
        // return (
        //   new Date(items.open_date + " " + items.open_time).getTime() <= today
        // );
        //return items.home_team_score;
        //});

        return this.matchDetail;
      }
      return [];
    },
  },
  created() {
    axios
      .get(`${this.currentHost}/api/banner`)
      .then((response) => {
        const axiosBannerList = response.data.data;
        this.bannerSettings = axiosBannerList;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  mounted() {
    this.active_class = window.location.pathname.split("/")[2];
    //Api match detail
  },
  methods: {
    infiniteHandler($state) {
      var _this = this;
      var page = this.page;

      if (_this.hasLastPage) {
        axios
          .get(`https://72963.com/api/match-result?page=${page}`)
          .then((response) => {
            var arr = response.data.data;

            arr.forEach(function (item) {
              _this.matchDetail.push(item);
            });
            if (arr.length > 0) {
              $state.loaded();
            } else {
              _this.hasLastPage = false;
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {});
      }

      _this.page = _this.page + 1;
    },
    toggleModal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var handicap_name = this.$i18n.locale == "vi" ? "handicap_desc_vi" : "handicap_desc";
      var handicap_desc = this.dataMatchObj[id][handicap_name] != null && this.dataMatchObj[id][handicap_name] != ''? this.dataMatchObj[id][handicap_name]:this.dataMatchObj[id]['handicap_desc'];
      this.currentUnravel = handicap_desc;
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].show();
    },
    toggleModalReal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var real_order_name_default = 'real_order';
      var real_order_name = this.$i18n.locale == "vi" ? "real_order_vi" : "real_order";
      console.log(real_order_name);
      var real_order = this.matchDetail[id][real_order_name] != null && this.matchDetail[id][real_order_name] != ''? this.matchDetail[id][real_order_name]:this.matchDetail[id][real_order_name_default];
      this.currentRealOrder = real_order;
      this.currentRealOrderDetail = this.matchDetail[id];
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal-real-order"].show();
    },
    toggleModalMatch(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var handicap_name = this.$i18n.locale == "vi" ? "handicap_desc_vi" : "handicap_desc";
      var handicap_desc = this.matchDetail[id][handicap_name] != null && this.matchDetail[id][handicap_name] != ''? this.matchDetail[id][handicap_name]:this.matchDetail[id]['handicap_desc'];
      this.currentUnravel = handicap_desc;
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].show();
    },
    toggleModalMatchReal(e) {
      e.preventDefault();
      var id = e.target.dataset.id;
      var real_order_name_default = 'real_order';
      var real_order_name = this.$i18n.locale == "vi" ? "real_order_vi" : "real_order";

      var real_order = this.matchDetail[id][real_order_name] != null && this.matchDetail[id][real_order_name] != ''? this.matchDetail[id][real_order_name]:this.matchDetail[id][real_order_name_default];
      this.currentRealOrder = real_order;
      this.currentRealOrderDetail = this.matchDetail[id];
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal-real-order"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.$refs["my-modal-real-order"].hide();
    },
  },
};
</script>

<style scope>
.final-score {
  padding: 15px 25px;
  margin-bottom: 15px;
  margin-top: 5px;
  border-radius: 5px;
}
.column-cell-2 .home-team,
.column-cell-2 .away-team,
.column-cell-4 .home-team,
.column-cell-4 .away-team {
  padding: 10px 35px;
  margin-bottom: 15px;
  margin-top: 20px;
  border-radius: 5px;
}
</style>
